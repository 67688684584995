import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
	return (
		<main id="footer-container">
            <p>COPYRIGHT © 2023 EVERYONE'S BOARD
			<br />ALL RIGHTS RESERVED</p>
        </main>
	);
}

export default Footer;