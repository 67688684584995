import "./About.css"

const About = () => {
    return (
        <div>
            About
        </div>
    )
}

export default About;